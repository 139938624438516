import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

import device from 'utils/device'
import Svg from 'components/Svg'
import Reveal from 'components/Reveal'

export default function Expect() {
    const { t } = useTranslation('home')

    const items = t('home_expect_blocks', {}, { returnObjects: true })

    return (
        <Container>
            <Reveal>
            <h2 className="h2">{t('home_expect_name')}</h2>
            </Reveal>
            <Wrap>
                {items.map((item, index) => (
                    <Reveal key={index}>
                    <Block>
                        <Svg name={`expect-${index+1}`}/>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>
                    </Block>
                    </Reveal>
                ))}
            </Wrap>
        </Container>
    )
}

const Container = styled.div`
    padding: 1.6rem 0;
    h2 {
        text-align: center;
        margin-bottom: 0.36rem;
    }
    ${device.mobile} {
        padding: 0.8rem 0.16rem;
    }
`

const Wrap = styled.div`
    width: 12rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${device.mobile} {
        width: auto;
        display: block;
    }
`


const Block = styled.div`
    background: #fff;
    border-radius: 0.24rem;
    width: 3.84rem;
    padding: 0.36rem;
    svg {
        width: 0.36rem;
        height: 0.36rem;
        display: block;
        margin: 0 0 0.24rem 0;
        fill: #38869e;
    }
    h3 {
        font-size: 0.22rem;
        margin: 0 0 0.16rem 0;
        font-weight: 600;
        padding-right: 15%;
    }
    ${device.mobile} {
        width: auto;
        padding: 0.24rem;
        margin-bottom: 0.16rem;
    }
`



